import httpClient from '@/utilities/http/http-client'
import _ from 'lodash'

const endpoint = 'api/v1/postingTransaksi'

export default {
  getTransaction (params) {
    const paramsFiltered = _.omitBy(params, _.isNull)
    const queryParams = paramsFiltered ? `?${new URLSearchParams(paramsFiltered).toString()}` : ''
    return httpClient.get(`${endpoint}/getTransaction${queryParams}`)
  },

  getJurnal (params) {
    const queryParams = params ? `?${new URLSearchParams(params).toString()}` : ''
    return httpClient.get(`${endpoint}/getJurnal${queryParams}`)
  },

  posting (params) {
    return httpClient.post(`${endpoint}/posting`, params)
  }
}
