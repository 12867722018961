import _ from 'lodash'

const getDefaultState = () => {
  return {
    headerSection: {
      tabs: {
        active: 'SPK'
      }
    },
    footerSection: {

    },
    transactionSection: {
      items: []
    },
    jurnalSection: {

    }
  }
}

export default {
  namespaced: true,

  state: getDefaultState(),

  getters: {
    checkedTransaction: (state) => {
      return _.filter(state.transactionSection.items, item => (!item.id_jurnal && item.checked))
    },

    undoPostingTransaction: (state) => {
      return _.filter(state.transactionSection.items, item => (item.id_jurnal && !item.checked))
    }
  },

  mutations: {
    SET_HEADER_SECTION (state, payload) {
      state.headerSection = payload
    },
    SET_FOOTER_SECTION (state, payload) {
      state.footerSection = payload
    },
    SET_TRANSACTION_SECTION (state, payload) {
      state.transactionSection = payload
    },
    SET_JURNAL_SECTION (state, payload) {
      state.jurnalSection = payload
    },
    RESET_STATE (state) {
      Object.assign(state, getDefaultState())
    }
  },

  actions: {}
}
