<template>
  <div class="h-full">
    <div class="border border-solid d-theme-border-grey-light h-full">
      <div class="w-full flex justify-between" style="background-color: rgba(121,121,121,0.1);">
        <div class="flex items-center justify-start">
          <p class="text-xs font-medium p-2 text-dark text-bold">
            {{ data.loading ? 'Loading...' : 'Transaction List' }}
          </p>
          <QueryEditor v-if="$can('view_query')" :code="currentQueryCode"/>
        </div>
        <vs-checkbox size="small" class="text-xs" v-model="data.checkAll" @change="toggleCheckAll($event.target.checked)">Check All</vs-checkbox>
      </div>
      <div class="h-full">
        <div class="tableSticky">
          <table class="w-full h-full border-collapse">
            <thead class="font-bold">
              <tr class="d-theme-dark-bg">
                <th class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light text-center whitespace-no-wrap"></th>
                <th class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light text-center whitespace-no-wrap">#</th>
                <th class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light text-center whitespace-no-wrap">Nomor</th>
                <th class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light text-center whitespace-no-wrap">Proyek</th>
                <th class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light text-center whitespace-no-wrap">Tgl</th>
                <th class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light text-center whitespace-no-wrap">Total</th>
                <th class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light text-center whitespace-no-wrap">Post</th>
                <th class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light text-center whitespace-no-wrap">Keterangan</th>
              </tr>
            </thead>
            <tbody class="font-medium">
            <tr v-for="(item, index) in data.items"
                :key="index" class="cursor-pointer hover:font-medium"
                :class="{'bg-success-transparent-25': item.id_jurnal}"
                @click="data.selected = item">
                <td class="text-xx border border-solid d-theme-border-grey-light text-center whitespace-no-wrap">
                  <div class="flex items-center">
                    <vs-icon v-if="item === data.selected" size="14px" color="danger" icon-pack="material-icons" icon="play_arrow"/>
                  </div>
                </td>
                <td class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light text-center whitespace-no-wrap">
                  {{ index + 1 }}
                </td>
                <td class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light text-center whitespace-no-wrap">
                  {{ item.nomor }}
                </td>
                <td class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light text-center whitespace-no-wrap">
                  {{ item.proyek }}
                </td>
                <td class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light text-center whitespace-no-wrap">
                  {{ item.tgl }}
                </td>
                <td class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light text-right whitespace-no-wrap">
                  {{ item.total | idr }}
                </td>
                <td class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light text-center whitespace-no-wrap">
                  <vs-checkbox :disabled="item.id_jurnal" size="small" v-model="item.checked"/>
                </td>
                <td class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light text-left whitespace-no-wrap">
                  {{ item.keterangan }}
                </td>
              </tr>
              <!--empty state-->
              <tr v-if="data.items.length < 1">
                <td colspan="7" class="text-xs text-center p-2">Tidak ada data.</td>
              </tr>
              <!--spacer-->
              <tr v-if="data.items.length > 0" class="h-full">
                <td colspan="7"></td>
              </tr>
            </tbody>
            <tfoot>
              <tr class="d-theme-dark-bg border border-solid d-theme-border-grey-light">
                <th class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light text-right whitespace-no-wrap"></th>
                <th class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light text-right whitespace-no-wrap"></th>
                <th class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light text-right whitespace-no-wrap"></th>
                <th class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light text-right whitespace-no-wrap"></th>
                <th class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light text-right whitespace-no-wrap"></th>
                <th class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light text-right whitespace-no-wrap">{{ footer.grandTotal | idr }}</th>
                <th class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light text-right whitespace-no-wrap"></th>
              </tr>
            </tfoot>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PostingTransaksiRepository from '@/repositories/accounting/posting-transaksi-repository'
import _ from 'lodash'

export default {
  name: 'TransactionsSection',
  components: {
    QueryEditor: () => import('@/views/components/query-editor/QueryEditor')
  },
  computed: {
    dataHeaderSection () {
      return this.$store.state.accounting.postingTransaksi.headerSection
    },
    dataFooterSection () {
      return this.$store.state.accounting.postingTransaksi.footerSection
    },
    footer () {
      return { grandTotal: _.sumBy(this.data.items, item => parseFloat(item.total)) }
    },
    currentQueryCode () {
      const currentActiveTab = this.dataHeaderSection.tabs.active
      switch (currentActiveTab) {
        case 'SPK':
          return 'POSTING_SPK_TRANSACTION_LIST'
        case 'SPK ADJUST':
          return 'POSTING_SPK_ADJUST_TRANSACTION_LIST'
        case 'PO':
          return 'POSTING_PO_TRANSACTION_LIST'
        case 'KOMISI':
          return 'POSTING_KOMISI_TRANSACTION_LIST'
        case 'REIMBURSE':
          return 'POSTING_REIMBURSE_TRANSACTION_LIST'
        case 'AP OTHER':
          return 'POSTING_AP_OTHER_TRANSACTION_LIST'
        case 'KONTRAK':
          return 'POSTING_KONTRAK_TRANSACTION_LIST'
        case 'IPL':
          return 'POSTING_IPL_TRANSACTION_LIST'
        case 'POS':
          return 'POSTING_POS_TRANSACTION_LIST'
        case 'CASH ADVANCE':
          return 'POSTING_CASH_ADVANCE_TRANSACTION_LIST'
        case 'AR OTHER':
          return 'POSTING_AR_OTHER_TRANSACTION_LIST'
        default:
          return null
      }
    }
  },
  data () {
    return {
      data: {
        loading: false,
        checkAll: false,
        selected: null,
        items: []
      }
    }
  },
  watch: {
    'dataHeaderSection.tabs.active' (newVal, oldVal) {
      if (!_.isEqual(newVal, oldVal)) {
        this.refresh()
      }
    },
    data: {
      deep: true,
      handler (value) {
        this.$store.commit('accounting/postingTransaksi/SET_TRANSACTION_SECTION', _.cloneDeep(value))
      }
    }
  },
  methods: {
    getData () {
      this.data.loading = true

      const params = {
        type: this.dataHeaderSection.tabs.active,
        id_proyek: this.dataHeaderSection.filter.id_proyek,
        periode_awal: this.dataHeaderSection.filter.periode_awal,
        periode_akhir: this.dataHeaderSection.filter.periode_akhir
      }
      PostingTransaksiRepository.getTransaction(params)
        .then(response => {
          this.data.items = _.map(response.data.data, function (item) {
            const additionalKeys = { checked: !!item.id_jurnal }
            return _.extend({}, item, additionalKeys)
          })
          if (this.data.items.length > 0) {
            this.data.selected = this.data.items[0]
          }
        })
        .catch(error => {
          console.log(error)
          this.notifyError('Terjadi kesalahan.')
        })
        .finally(() => {
          this.data.loading = false
        })
    },

    refresh () {
      this.data.items = []
      this.data.selected = null
      this.data.checkAll = false
      this.getData()
    },

    toggleCheckAll (isChecked) {
      this.data.items = _.map(this.data.items, item => {
        if (!item.id_jurnal) {
          item.checked = isChecked
        }
        return item
      })
    }
  }
}
</script>

<style>
.tableSticky {
  overflow: auto;
  height: calc(100vh - 305px);
}

.tableSticky thead tr {
  position: sticky;
  top: -1px;
  z-index: 1;
}

.tableSticky tfoot tr {
  position: sticky;
  bottom: -1px;
  z-index: 1;
}
</style>
