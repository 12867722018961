<template>
  <div class="h-full">
    <div class="border border-solid d-theme-border-grey-light h-full">
      <div class="w-full flex justify-between" style="background-color: rgba(121,121,121,0.1);">
        <div class="flex items-center justify-start">
          <p class="text-xs font-medium p-2 text-dark text-bold">
            {{ data.loading ? 'Loading...' : 'Jurnal' }}
          </p>
          <QueryEditor v-if="$can('view_query')" :code="currentQueryCode"/>
        </div>
      </div>
      <div class="h-full">
        <div v-if="!selectedTransaction" class="w-full h-full flex justify-center items-center">
          <p class="text-xs">Pilih transaksi disebelah kiri untuk melihat data.</p>
        </div>
        <div v-else class="tableSticky">
          <table class="w-full h-full border-collapse">
            <thead class="font-extrabold border border-solid d-theme-border-grey-light">
              <tr class="d-theme-dark-bg border border-solid d-theme-border-grey-light">
                <th colspan="3" class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light text-center whitespace-no-wrap">Deskripsi</th>
                <th colspan="2" class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light text-center whitespace-no-wrap">Total</th>
              </tr>
              <tr class="d-theme-dark-bg border border-solid d-theme-border-grey-light">
                <th class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light text-center whitespace-no-wrap">Kode COA</th>
                <th class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light text-center whitespace-no-wrap">Nama COA</th>
                <th class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light text-center whitespace-no-wrap">Keterangan</th>
                <th class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light text-center whitespace-no-wrap">Debet</th>
                <th class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light text-center whitespace-no-wrap">Kredit</th>
              </tr>
            </thead>
            <tbody class="font-medium">
              <tr v-for="(item, index) in data.items" :key="index" class="cursor-pointer hover:font-medium">
                <td class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light text-left whitespace-no-wrap">
                  <div class="flex items-center">
                    <feather-icon v-if="isTransactionChecked && !isTransactionAlreadyPosted" icon="EditIcon" svgClasses="h-3 w-3 text-warning" class="mr-2" @click="onClickEdit(item)"/>
                    <span>{{ item.kode_coa }}</span>
                  </div>
                </td>
                <td class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light text-left whitespace-no-wrap">{{ item.nama_coa }}</td>
                <td class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light text-left whitespace-no-wrap">{{ item.keterangan }}</td>
                <td class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light text-right whitespace-no-wrap">{{ item.debet | idr }}</td>
                <td class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light text-right whitespace-no-wrap">{{ item.kredit | idr }}</td>
              </tr>
              <!--empty state-->
              <tr v-if="data.items.length < 1">
                <td colspan="6" class="text-xs text-center p-2">Tidak ada data.</td>
              </tr>
              <!--spacer-->
              <tr v-if="data.items.length > 0" class="h-full">
                <td colspan="5"></td>
              </tr>
            </tbody>
            <tfoot>
              <tr class="d-theme-dark-bg border border-solid d-theme-border-grey-light">
                <th class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light text-right whitespace-no-wrap"></th>
                <th class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light text-right whitespace-no-wrap"></th>
                <th class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light text-right whitespace-no-wrap"></th>
                <th class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light text-right whitespace-no-wrap">{{ footer.totalDebet | idr }}</th>
                <th class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light text-right whitespace-no-wrap">{{ footer.totalKredit | idr }}</th>
              </tr>
            </tfoot>
          </table>
        </div>
      </div>
    </div>

    <!--modals-->
    <vs-popup class="sm:popup-w-50 popup-content-no-padding"
              title="Ganti COA"
              :active="modalCoa.active"
              v-on:update:active="modalCoa.active = $event">
      <Coa :selectable="true" :externalFilter="filterCoa" @selected="onSelectedModalCoa"/>
    </vs-popup>
  </div>
</template>

<script>
import PostingTransaksiRepository from '@/repositories/accounting/posting-transaksi-repository'
import { v4 as uuid } from 'uuid'
import _ from 'lodash'

export default {
  name: 'JurnalsSection',
  components: {
    QueryEditor: () => import('@/views/components/query-editor/QueryEditor'),
    Coa: () => import('@/views/pages/master/coa/Coa')
  },
  computed: {
    dataHeaderSection () {
      return this.$store.state.accounting.postingTransaksi.headerSection
    },
    dataFooterSection () {
      return this.$store.state.accounting.postingTransaksi.footerSection
    },
    dataTransactionSection () {
      return this.$store.state.accounting.postingTransaksi.transactionSection
    },
    selectedTransaction () {
      return this.dataTransactionSection.selected
    },
    isTransactionChecked () {
      return this.selectedTransaction.checked
    },
    isTransactionAlreadyPosted () {
      return !!this.selectedTransaction.id_jurnal
    },
    footer () {
      return {
        totalDebet: _.sumBy(this.data.items, item => parseFloat(item.debet)),
        totalKredit: _.sumBy(this.data.items, item => parseFloat(item.kredit))
      }
    },
    currentQueryCode () {
      const currentActiveTab = this.dataHeaderSection.tabs.active
      switch (currentActiveTab) {
        case 'SPK':
          return 'POSTING_SPK_JURNAL'
        case 'SPK ADJUST':
          return 'POSTING_SPK_ADJUST_JURNAL'
        case 'PO':
          return 'POSTING_PO_JURNAL'
        case 'KOMISI':
          return 'POSTING_KOMISI_JURNAL'
        case 'REIMBURSE':
          return 'POSTING_REIMBURSE_JURNAL'
        case 'AP OTHER':
          return 'POSTING_AP_OTHER_JURNAL'
        case 'KONTRAK':
          return 'POSTING_KONTRAK_JURNAL'
        case 'IPL':
          return 'POSTING_IPL_JURNAL'
        case 'POS':
          return 'POSTING_POS_JURNAL'
        case 'CASH ADVANCE':
          return 'POSTING_CASH_ADVANCE_JURNAL'
        case 'AR OTHER':
          return 'POSTING_AR_OTHER_JURNAL'
        default:
          return null
      }
    },
    filterCoa () {
      return {
        tipe: 'DETAIL',
        no_generate: this.dataHeaderSection.filter.no_generate,
        id_proyek: this.dataHeaderSection.filter.id_proyek
      }
    }
  },
  data () {
    return {
      modalCoa: {
        active: false,
        uuid: null
      },
      data: {
        loading: false,
        items: [],
        jurnalsChanged: []
      }
    }
  },
  watch: {
    'dataHeaderSection.tabs.active' (newVal, oldVal) {
      if (!_.isEqual(newVal, oldVal)) {
        this.resetJurnalsChanged()
      }
    },
    selectedTransaction (newVal, oldVal) {
      const newId = newVal ? newVal.id : null
      const oldId = oldVal ? oldVal.id : null
      if (!_.isEmpty(newVal) && !_.isEqual(newId, oldId)) {
        this.refresh()
      }
    },
    data: {
      deep: true,
      handler (value) {
        const payload = { ...value, totalDebet: this.footer.totalDebet, totalKredit: this.footer.totalKredit }
        this.$store.commit('accounting/postingTransaksi/SET_JURNAL_SECTION', _.cloneDeep(payload))
      }
    }
  },
  methods: {
    onClickEdit (item) {
      this.showModalCoa(item)
    },

    showModalCoa (item) {
      this.modalCoa.uuid = item.uuid
      this.modalCoa.active = true
    },

    onSelectedModalCoa (coa) {
      // update item
      const index = _.findIndex(this.data.items, item => item.uuid === this.modalCoa.uuid)
      this.data.items[index].id_coa = coa.id
      this.data.items[index].kode_coa = coa.kode
      this.data.items[index].nama_coa = coa.nama

      // push to jurnal changed list
      const indexJurnalChanged = _.findIndex(this.data.jurnalsChanged, item => (item.id_trx === this.selectedTransaction.id && item.index_jurnal === index))
      const jurnalChanged = {
        id_trx: this.selectedTransaction.id,
        index_jurnal: index,
        id_ref: this.data.items[index].id_ref,
        tipe: this.data.items[index].tipe,
        id_coa: coa.id,
        kode_coa: coa.kode,
        nama_coa: coa.nama
      }
      if (indexJurnalChanged === -1) {
        this.data.jurnalsChanged.push(jurnalChanged)
      } else {
        this.data.jurnalsChanged[indexJurnalChanged] = jurnalChanged
      }

      // hide modal
      this.modalCoa.active = false
    },

    getJurnal () {
      this.data.loading = true

      const params = {
        type: this.dataHeaderSection.tabs.active,
        id: this.selectedTransaction.id
      }
      PostingTransaksiRepository.getJurnal(params)
        .then(response => {
          this.data.items = _.map(response.data.data, (item, index) => {
            item.uuid = uuid()

            // check if jurnal already changed
            const jurnalChanged = _.find(this.data.jurnalsChanged, item => (item.id_trx === this.selectedTransaction.id && item.index_jurnal === index))
            if (jurnalChanged) {
              item.id_coa = jurnalChanged.id_coa
              item.kode_coa = jurnalChanged.kode_coa
              item.nama_coa = jurnalChanged.nama_coa
            }

            return item
          })
        })
        .catch(error => {
          console.log(error)
          this.notifyError('Terjadi kesalahan.')
        })
        .finally(() => {
          this.data.loading = false
        })
    },

    refresh () {
      this.data.items = []
      this.getJurnal()
    },

    resetJurnalsChanged () {
      this.data.jurnalsChanged = []
    }
  }
}
</script>

<style>
.tableSticky {
  overflow: auto;
  height: calc(100vh - 305px);
}
.tableSticky thead {
  position: sticky;
  top: -1px;
  z-index: 1;
}
.tableSticky tfoot {
  position: sticky;
  bottom: -1px;
  z-index: 1;
}
</style>
