<template>
  <div class="flex flex-col no-scroll-content">
    <!------------ header ------------->
    <div class="flex-none">
      <div class="p-2 vx-card shadow-none rounded-none border-solid d-theme-border-grey-light border border-b-0">
        <div class="flex flex-wrap">
          <div class="px-2 sm:w-3/12 w-full">
            <div class="flex items-center text-xs">
              <label class="mr-1 text-xs whitespace-no-wrap">Proyek</label>
                <vs-select class="vs-select-small" :value="null" v-model="headerSection.filter.id_proyek">
                  <vs-select-item class="vs-select-small" text="Semua"/>
                  <vs-select-item class="vs-select-small" v-for="(it, index) in proyeks" :key="index" :value="it.id" :text="`${it.kode} - ${it.nama}`"/>
                </vs-select>
            </div>
          </div>
          <div class="px-2 sm:w-3/12 w-full">
            <div class="flex items-center">
              <label class="mr-1 text-xs whitespace-no-wrap">Periode</label>
              <vx-input-group>
                <template slot="prepend">
                  <vx-input-group>
                    <flat-pickr class="w-full flatpickr-input rounded-none text-xs" v-model="headerSection.filter.periode_awal"></flat-pickr>
                  </vx-input-group>
                  <div class="text-xs px-2 flex items-center border border-r-0 border-l-0 border-solid rounded-none d-theme-border-grey-light" style="background-color: rgba(121,121,121,0.1); border-color: rgba(71,71,71,0.3)">
                    <span class="p-0">s/d</span>
                  </div>
                  <vx-input-group>
                    <flat-pickr class="w-full flatpickr-input rounded-none text-xs" v-model="headerSection.filter.periode_akhir"></flat-pickr>
                  </vx-input-group>
                </template>
              </vx-input-group>
            </div>
          </div>
          <div class="px-2 sm:w-1/12 w-full">
            <vs-button class="px-3 py-3 text-xs text-center" color="primary" @click="filter">Filter</vs-button>
          </div>
        </div>
      </div>
      <div class="p-0 vx-card shadow-none rounded-none border-solid d-theme-border-grey-light border border-b-0">
        <div class="flex h-auto" :class="{'opacity-50 pointer-events-none': dataTransactionSection.loading}">
          <ul class="flex items-center text-xs overflow-x-auto">
            <li v-for="(tab, index) in headerSection.tabs.items"
                :key="index"
                :class="{'text-primary font-bold': isTabActive(tab.code), 'hover-bg-primary-transparent-25': !isTabActive(tab.code)}"
                @click="headerSection.tabs.active = tab.code"
                class="cursor-pointer px-2 py-1 select-none whitespace-no-wrap border-solid d-theme-border-grey-light border-b-0 border-t-0 border-l-0 border-r">
              {{ tab.name }}
            </li>
          </ul>
        </div>
      </div>
    </div>

    <!----------- content ------------->
    <div class="flex-grow">
      <div class="flex h-full">
        <div class="w-full sm:w-4/12 pr-1 d-theme-dark-bg">
          <TransaksiSection ref="transactionSection"/>
        </div>
        <div class="w-full sm:w-8/12 pl-1 d-theme-dark-bg">
          <JurnalSection ref="jurnalSection"/>
        </div>
      </div>
    </div>

    <!------------ footer ------------->
    <div class="flex-none vx-card p-2 rounded-none">
      <div class="flex flex-wrap items-center">
        <div class="sm:w-4/12 w-full">
          <div class="flex">
            <vs-button color="success" size="small" class="px-3 mr-2" icon-pack="feather" icon="icon-check" :disabled="loadingPosting || checkedTransaction.length < 1" @click="confirmPosting">
              <span v-if="loadingPosting" class="animate-pulse">Posting...</span>
              <span v-else>Posting {{ checkedTransaction.length }} Transaksi</span>
            </vs-button>
          </div>
        </div>
        <div class="sm:w-8/12 w-full">
          <div class="flex items-center justify-between">
            <div class="flex items-center"></div>
              <vs-button @click="showModalSetupOtherCoa" color="primary" type="flat" size="small" class="px-3" icon-pack="feather" icon="icon-book">Setup Other COA</vs-button>
          </div>
        </div>
      </div>
    </div>

    <!--modals-->
    <SetupOtherCoa :isActive.sync="modalSetupOtherCoaActive"/>

  </div>
</template>

<script>
import TransaksiSection from '@/views/pages/accounting/posting-transaksi/transactions/TransactionsSection'
import JurnalSection from '@/views/pages/accounting/posting-transaksi/jurnals/JurnalsSection'
import ProyekRepository from '@/repositories/master/proyek-repository'
import PostingTransaksiRepository from '@/repositories/accounting/posting-transaksi-repository'
import modulePostingTransaksi from '@/store/modules/accounting/posting-transaksi/posting-transaksi.store'
import 'flatpickr/dist/flatpickr.css'
import flatPickr from 'vue-flatpickr-component'
import moment from 'moment'
import _ from 'lodash'

export default {
  name: 'PostingTransaksi',
  components: {
    TransaksiSection,
    JurnalSection,
    flatPickr,
    SetupOtherCoa: () => import('@/views/pages/accounting/posting-transaksi/SetupOtherCoa')
  },
  mounted () {
    this.getProyek()
  },
  computed: {
    checkedTransaction () {
      return this.$store.getters['accounting/postingTransaksi/checkedTransaction']
    },
    dataTransactionSection () {
      return this.$store.state.accounting.postingTransaksi.transactionSection
    },
    dataJurnalSection () {
      return this.$store.state.accounting.postingTransaksi.jurnalSection
    }
  },
  data () {
    return {
      loadingPosting: false,
      modalSetupOtherCoaActive: false,
      headerSection: {
        filter: {
          id_proyek: null,
          periode_awal: moment().startOf('month').format('YYYY-MM-DD'),
          periode_akhir: moment().endOf('month').format('YYYY-MM-DD')
        },
        tabs: {
          items: [
            { code: 'SPK', name: 'SPP' },
            { code: 'SPK ADJUST', name: 'SPP ADJUST' },
            { code: 'PO', name: 'PO' },
            { code: 'KOMISI', name: 'KOMISI' },
            { code: 'REIMBURSE', name: 'REIMBURSE' },
            { code: 'AP OTHER', name: 'AP OTHER' },
            { code: 'KONTRAK', name: 'KONTRAK' },
            { code: 'IPL', name: 'IPL' },
            { code: 'POS', name: 'POS' },
            { code: 'CASH ADVANCE', name: 'CASH ADVANCE' },
            { code: 'AR OTHER', name: 'AR OTHER' }
          ],
          active: 'SPK'
        }
      },
      footerSection: {},
      proyeks: []
    }
  },
  watch: {
    headerSection: {
      deep: true,
      handler (value) {
        this.commitHeaderSectionToStore(value)
      }
    },
    footerSection: {
      deep: true,
      handler (value) {
        this.commitFooterSectionToStore(value)
      }
    }
  },
  methods: {
    getProyek () {
      const params = {
        filter: JSON.stringify({
          only_authorized_user: 1
        })
      }
      ProyekRepository.getAll(params)
        .then(response => {
          this.proyeks = response.data.data
        })
        .catch(error => {
          console.log(error)
        })
    },

    confirmPosting () {
      this.$vs.dialog({
        type: 'confirm',
        color: 'success',
        title: 'Konfirmasi',
        text: `Yakin ingin memposting ${this.checkedTransaction.length} transaksi?`,
        acceptText: 'Ya, Lanjutkan',
        cancelText: 'Batal',
        accept: () => { this.posting() }
      })
    },

    showModalSetupOtherCoa () {
      this.modalSetupOtherCoaActive = true
    },

    posting () {
      this.loadingPosting = true

      const params = this.buildParams()
      PostingTransaksiRepository.posting(params)
        .then(response => {
          this.onSuccessPosting()
        })
        .catch(error => {
          console.log(error)
          if (error.response.status === 422) {
            const errors = error.response.data.errors
            this.notifyErrorUsingDialog(errors)
          } else {
            const message = error.response.data.message ? error.response.data.message : 'Terjadi kesalahan.'
            this.notifyError(message)
          }
        })
        .finally(() => {
          this.loadingPosting = false
        })
    },

    buildParams () {
      return {
        type: this.headerSection.tabs.active,
        transactions: _.map(this.checkedTransaction, item => {
          return {
            id: item.id,
            kode_proyek: item.proyek,
            tgl: item.tgl
          }
        }),
        jurnalItemsChanged: _.map(this.dataJurnalSection.jurnalsChanged, item => {
          return {
            id_trx: item.id_trx,
            id_ref: item.id_ref,
            index_jurnal: item.index_jurnal,
            tipe: item.tipe,
            id_coa: item.id_coa
          }
        })
      }
    },

    onSuccessPosting () {
      this.notifySuccess('Berhasil posting.')
      this.filter()
    },

    filter () {
      this.$refs.transactionSection.refresh()
      this.$refs.jurnalSection.resetJurnalsChanged()
    },

    isTabActive (tabCode) {
      return tabCode === this.headerSection.tabs.active
    },

    commitHeaderSectionToStore (value) {
      this.$store.commit('accounting/postingTransaksi/SET_HEADER_SECTION', _.cloneDeep(value))
    },

    commitFooterSectionToStore (value) {
      this.$store.commit('accounting/postingTransaksi/SET_FOOTER_SECTION', _.cloneDeep(value))
    }
  },
  created () {
    this.commitHeaderSectionToStore(this.headerSection)
    this.commitFooterSectionToStore(this.footerSection)
  },
  beforeCreate () {
    this.$store.registerModule(['accounting', 'postingTransaksi'], modulePostingTransaksi)
  },
  beforeDestroy () {
    this.$store.commit('accounting/postingTransaksi/RESET_STATE')
    this.$store.unregisterModule(['accounting', 'postingTransaksi'])
  }
}
</script>
